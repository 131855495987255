<template>
  <step-lesson-layout :title="$t('module2.part2.lesson.title')" :title-annotation="$t('module2.part2.lesson.titleAnnotation')" class="module2-part2-lesson">
    <template v-slot:content>
      <!-- INTRO -->
      <lesson-video-block :button-label="$t('module2.part2.lesson.videoButton1')"
        video="/videos/oscar-operation-cee.mp4"
        subtitles="/subtitles/oscar-operation-cee.vtt"
        :poster="require('@/assets/module2/part2/thumbnail-operation-cee.svg')"
        :enable-resume="false">
      </lesson-video-block>

      <!-- BLOCK 1 -->
      <div class="app-text-block">
        <p class="title">{{ $t('module2.part2.lesson.block1.title') }}</p>
        <p class="paragraphe">{{ $t('module2.part2.lesson.block1.content') }}</p>
        <lesson-video-block :button-label="$t('module2.part2.lesson.block1.videoButton')"
          video="/videos/oscar-operation-cdp.mp4"
          subtitles="/subtitles/oscar-operation-cdp.vtt"
          :poster="require('@/assets/module2/part2/thumbnail-operation-cdp.png')"
          :enable-resume="false">
        </lesson-video-block>
      </div>

      <!-- BLOCK 2 -->
      <div class="app-text-block links-block">
        <p class="title">{{ $t('module2.part2.lesson.block2.title') }}</p>
        <div class="paragraphe">
          <p class="label">{{ $t('module2.part2.lesson.block2.links1.title') }}</p>
          <p>{{ $t('module2.part2.lesson.block2.links1.content') }}</p>
          <ul>
            <li>
              {{ $t('module2.part2.lesson.block2.via') }}<a href="https://www.ecologie.gouv.fr/operations-standardisees-deconomies-denergie" target="_blank" rel="noopener">{{ $t('module2.part2.lesson.block2.links1.link1') }}</a>{{ $t('module2.part2.lesson.block2.links1.link1Content') }}
            </li>
            <li>
              {{ $t('module2.part2.lesson.block2.surLe') }}<a href="https://atee.fr/efficacite-energetique/club-c2e/fiches-doperations-standardisees" target="_blank" rel="noopener">{{ $t('module2.part2.lesson.block2.links1.link3') }}</a>
            </li>
          </ul>
        </div>
        <div class="paragraphe">
          <p class="label">{{ $t('module2.part2.lesson.block2.links2.title') }}</p>
          <p>{{ $t('module2.part2.lesson.block2.links2.content1') }} <a href="https://www.ecologie.gouv.fr/politiques/certificats-economies-denergie" target="_blank" rel="noopener">{{ $t('module2.part2.lesson.block2.links2.link1') }}</a> <span v-html="$t('module2.part2.lesson.block2.links2.content2')"></span></p>
        </div>
        <div class="paragraphe">
          <p class="label">{{ $t('module2.part2.lesson.block2.links3.title') }}</p>
          <p>{{ $t('module2.part2.lesson.block2.links3.content1') }} <a href="https://www.ecologie.gouv.fr/politiques/certificats-economies-denergie" target="_blank" rel="noopener">{{ $t('module2.part2.lesson.block2.links3.link1') }}</a> {{ $t('module2.part2.lesson.block2.links3.content2') }}</p>
        </div>
      </div>

      <!-- END BLOCK -->
      <div class="app-decoration-block end-block">
        <p class="decoration-block-content">
          {{ $t('training.global.partFinished') }}
        </p>
        <app-button-layout @click="goToNextStep">
          {{ $t('global.navigation.resume') }}
        </app-button-layout>
        <app-button-layout color="alternate-secondary" @click="resumeLater">
          {{ $t('global.navigation.resumeLater') }}
        </app-button-layout>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import useTrainingUtils from '@/utils/useTrainingUtils'
import LessonVideoBlock from '@/components/training/LessonVideoBlock'

export default {
  name: 'Module2Part2Lesson',
  components: { LessonVideoBlock, StepLessonLayout, AppButtonLayout },
  setup (props, context) {
    const { resumeLater, goToNextStep } = useTrainingUtils()
    return { resumeLater, goToNextStep }
  }
}
</script>

<style lang="scss" scoped>
.module2-part2-lesson {
  padding: 0 $space-s;
  .app-text-block {
    padding: 0 $space-s;
  }
  .links-block {
    a {
      color: $c-text-dark;
    }
    .paragraphe {
      & + .paragraphe {
        margin-top: $space-m;
      }
      .label {
        font-weight: $fw-l;
        margin-top: $space-xs;
      }
    }
  }
  .end-block {
    margin: 0 auto;
  }
}
</style>
